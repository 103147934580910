/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.app-shadow-sm {
    box-shadow: rgba(0, 0, 0, 0.06) 0px 4px 6px -1px, rgba(0, 0, 0, 0.01) 0px 2px 4px -1px;
}
.app-shadow-lg {
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}
.side-col {
    @apply col-span-3;
}
.main-col {
    @apply col-span-4;
}
.double-main-col {
    @apply col-span-8;
}
.textinput {
    @apply app-shadow-sm text-lg py-2 px-3 border-2 mt-1 focus:border-blue-400 block w-full sm:text-sm rounded-sm;
}
.copyable-time {
    @apply main-col border w-full cursor-pointer hover:bg-blue-200 flex flex-row my-1 py-2 px-4;
}
.textinput-label {
    @apply block text-sm font-semibold text-gray-500 cursor-pointer;
}
.card-container {
    @apply app-shadow-lg px-10 py-5 bg-white my-3 overflow-hidden sm:rounded-sm;
}
.card-grid {
    @apply grid grid-cols-11 gap-4;
}
.card-grid-hovered {
    gap: 0px;
}
.copylabel {
    @apply text-gray-500 text-sm;
}
.option-btn{
    @apply relative inline-flex items-center px-2 py-2 first:rounded-l-md last:rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50
}
.option-btn.active {
    @apply bg-indigo-50 text-indigo-700
}
.vspace {
    @apply my-4
}
.delimiter {
    max-width: 4rem;
}